import { useState } from "react";
import "./Contact.css"
import Footer from "./Footer";
import toast, { Toaster } from 'react-hot-toast';


const Contact = () => {
    const [feedback, setFeedback] = useState('Webapp');

    const [userName, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [textareaText, setTextareaText] = useState('');

    let contactData = {
        "name": userName,
        "email": email,
        "phone": number,
        "message": textareaText
    }

    const handleChange = (e) => {
        setUsername(e.target.value)
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const handleNumberChange = (e) => {
        if (e.target.value.length < 11) {

            setNumber(e.target.value)
        }

    }
    const handleTextAreaChange = (e) => {
        setTextareaText(e.target.value)
    }
    var invalidChars = [
        "-",
        "+",
        "e",
        "E"
    ];
    const invalidCharacter = (e) => {

        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    }
    const handleSubmit = (e) => {
        // e.preventDefault();
        if (number.length === 10) {
            sendData(contactData)
            toast.success('Querry Sent')

            e.preventDefault();
        } else {
            sendData(contactData)
            toast.success('Querry Sent')

            e.preventDefault();
        }


    }

    const sendData = (data) => {
        fetch('https://backend.ocreditor.com/api/website/contact/add', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }


    return (
        <div className="conatctContainer">
            <div><Toaster position="bottom-right" /></div>

            <h1 id="contactHeader">Get in Touch</h1>
            <h3 id="contactDesc">Please fill the below form and we will get back to you</h3>

            <div className="conatctFormContainer">
                <form onSubmit={(e) => { handleSubmit(e) }} id="form">
                    {/* <div className="contactFormDiv" id="formHeader">
                            <span id="formTitle">This feedback is for:</span>

                            <input type="radio" id="webapp" name="radioSelection" value="Webapp" defaultChecked onClick={(e) => { setFeedback("Webapp") }} /> Webapp

                            <input type="radio" id="extension" name="radioSelection" value="Extension" onClick={(e) => { setFeedback("Extension") }} /> Extension

                            <input type="radio" id="both" name="radioSelection" value="Both" onClick={(e) => { setFeedback("Both") }} /> Both
                        </div> */}

                    <div className="contactFormDiv">
                        <input type="text" className="inputField" placeholder="Name*" required value={userName} onChange={(e) => { handleChange(e) }} />
                    </div>

                    <div className="contactFormDiv">
                        <input type="email" className="inputField" placeholder="Email*" required value={email} onChange={(e) => { handleEmailChange(e) }} />
                    </div>

                    <div className="contactFormDiv">
                        <input type="number" maxLength={10} className="inputField" placeholder="Phone" value={number} onChange={(e) => { handleNumberChange(e) }} onKeyDown={(e) => { invalidCharacter(e) }} />
                    </div>

                    <div className="contactFormDiv" id="textArea">
                        {/* <input type="textarea" className="inputField"  placeholder="feedback"  value={number} onChange={(e) => { handleNumberChange(e) }} /> */}
                        <textarea placeholder="Message" onChange={(e) => { handleTextAreaChange(e) }} />
                    </div>

                    <div className="contactFormDiv">
                        <input id="submit" type="submit" value="Submit" />

                    </div>

                </form>
            </div>
            <Footer />
        </div>
    )
}

export default Contact;