import { useEffect } from "react";
import "./resultPageAd.css";

const ResultPageAd = (props) => {

    useEffect(() => {
       window.addEventListener('load', function (){
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        })
        let adCont = document.querySelector('.adsbygoogle')

        const id = setInterval(triggerInterval, 100)

        function triggerInterval() {
            // console.log('triggerInterval function')
            if (adCont) {
                document.querySelector('.ad-resultpage').querySelector('.adsbygoogle').style.height = "100px";
                document.querySelector('#aswift_2_host').style.height = "73px"
                document.querySelector('#aswift_2').style.height = "73px"
                document.querySelector('#resultFooter').style.height = "142px"
                clearInterval(id)
            }

        }

    return () => {
        clearInterval(id);
    };

    }, []);

    return (
        <div className="ad-resultpage">
            <ins
                className="adsbygoogle"
                data-ad-client="ca-pub-7894154869919315"
                data-ad-format="auto"
                data-ad-slot="7987832891"
                data-full-width-responsive="true"
                style={{ display: "inline-block", width: "200px", height: "50px", position: "sticky" }}
            />
        </div>
    );
};
export default ResultPageAd;
