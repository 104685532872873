import './ReactCardSlider1.css';
// import { MdChevronLeft,MdChevronRight } from 'react-icons/md';
import leftIcon from "../images/leftNext.svg"
import rightIcon from "../images/rightNext.svg"

const ReactCardSlider1 = (props) => {
    const slideRight = () => {
        var slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft + 500;
    }

    const slideLeft = () => {
        var slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft - 500;
    }

    return (
        <div id="main-slider-container">
            {/* <div className='scrollerImg'>
                    <img src={leftIcon} onClick={slideLeft} />
                </div> */}
            <div id="slider">
                {
                    props.slides.map((slide, index) => {
                        return (
                            <a href={slide.link} target="_blank" key={index}>
                                <div className="slider-card" onClick={() => slide.clickEvent()}>
                                    <div className="slider-card-image" style={{ backgroundImage: `url(${slide.image})`, backgroundSize: 'cover' }}></div>
                                    <p className="slider-card-title">{slide.title}</p>
                                    <p className="slider-card-description">{slide.description}</p>
                                </div>
                            </a>

                        )
                        
                    })
                }
                {/* <div className='blogsAds'></div> */}

            </div>
            {/* <div className='scrollerImg' >
                    <img src={rightIcon} onClick={slideRight} />
                </div> */}

        </div>
    )
}
export default ReactCardSlider1;