import "./Ocr.css";
import { useState, useEffect, useContext } from "react";
import OcrUploadSection from "./OcrUploadSection";
import OcrProcessSection from "./OcrProcessSection";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { jsPDF } from "jspdf";
import UserContext from "../Usercontext";
import toast, { Toaster } from "react-hot-toast";
import dateTime from "date-time";
import ReactCardSlider1 from "../helper/ReactCardSlider1";
import Footer from "./Footer";
import AdBoxSquare from "./AdBoxSquare";
import SquareAds from "../helper/SquareAds";
import HorizontalAds from "../helper/HorizontalAds";
import VerticalAds from "../helper/VerticalAds";
import "./OcrProcessSection.css"
import galleryImage from "../images/image-gallery.svg";
import version from "../images/version.svg";
import extension from "../images/extension.svg";
import { Progress } from 'antd';
import copyIcon from "../images/copyIcon.svg";
import trashIcon from "../images/trashIcon.svg";
import UseCard from "./UseCard";
import arrowDown from "../images/arrowDown.svg";
import docIcon from "../images/doc.svg";
import txtIcon from "../images/txt.svg";
import pdfIcon from "../images/pdf.svg";
import Use from "./Use";
import CollapseMenu from "../helper/CollapseMenu";
import Feature1 from "../images/feature1.svg";
import Feature2 from "../images/feature2.svg";
import Feature3 from "../images/feature3.svg";
import WhyImg1 from "../images/whyImg1.svg";
import WhyImg2 from "../images/whyImg2.svg";
import WhyImg3 from "../images/whyImg3.svg";
import screenshot from "../images/screenshot.png";
import checkedIcon from "../images/checked.svg";
import chromePng from "../images/chromeIcon.png";
import edgePng from "../images/edgeIcon.png";
import mixpanel from "mixpanel-browser";
import SideBarAd from "./adsSection/SideBarAd";
import FooterAd from "./adsSection/FooterAd";
import { Modal } from "antd";
import MiddlePageAd from "./adsSection/middlePageAd";
import ModalAd from "./adsSection/modalAd";
import ResultPageAd from "./adsSection/resultPageAd";
import ResultPageAdComponent from "./adsSection/resultPageComponentAd";
import { Hidden } from "@mui/material";

const Ocr = (props) => {
  const [img, setImg] = useState("");
  const [ImageName, setImageName] = useState("");
  const [ImageSize, setImageSize] = useState("");
  const [processingTextContain, SetProcessingTextContain] = useState(false);
  const [modalVisible, setModalVisible] = useState(false)
  const [resultDisplay, setResultDisplay] = useState(false)
  const [progressBarStatus, setProgressBarStatus] = useState(0)
  const [UploadStatus, setUploadStatus] = useState(false)
  const [uploadText, setUploadText] = useState('uploading...')
  const {
    bodyContent,
    setBodyContent,
    displayResult,
    setDisplayResult,
    progressStatus,
    setProgressStatus,
    isOpen,
    setIsOpen,
    displayResultPage,
    setDisplayResultPage
  } = useContext(UserContext);

  const getData = (data) => {
    setImg(data);
    setDisplayResult(false);
    setModalVisible(true)

  };


  const getImg = (imgName) => {
    setImageName(imgName.name);
    setImageSize(imgName.size);

  };
  const getBodycontent = (content) => {
    setBodyContent("");
  };

  const getProcessingText = (Recognised) => {
    SetProcessingTextContain(Recognised);
  };
  let deleteText = () => {
    if (bodyContent) {
      setBodyContent("");
      setDisplayResult(true);
      setProgressStatus(0);
      toast.success("Deleted successfully");

      mixpanel.track("Clicked on Delete.", {});
    }
  };

  useEffect(() => {

  }, [bodyContent]);

  let date = dateTime();

  const generateTxt = () => {
    let text = bodyContent;
    if (text) {
      const blob = new Blob([text], { type: "text/plain" });
      if (window.navigator.msSaveOrOpenBlob && text.length > 0) {
        window.navigator.msSaveBlob(blob, "speech");
      } else {
        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = "OCR " + date;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
      toast.success("Downloaded Text");

      mixpanel.track("Clicked on Download - TEXT.", {});
    } else {
      toast.error("No text found");
    }
  };

  const generateDoc = () => {
    let text = bodyContent;
    if (text) {
      const blob = new Blob([text], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      if (window.navigator.msSaveOrOpenBlob && text.length > 0) {
        window.navigator.msSaveBlob(blob, "speech");
      } else {
        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = "OCR " + date;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
      toast.success("Downloaded Document");

      mixpanel.track("Clicked on Download - DOC.", {});
    } else {
      toast.error("No text found");
    }
  };

  const generatePdf = () => {
    const doc = new jsPDF();

    if (bodyContent) {
      doc.text(bodyContent, 10, 10);
      doc.save(`OCR ${date}.pdf`);
      toast.success("Downloaded pdf");

      mixpanel.track("Clicked on Download - PDF.", {});
    } else {
      toast.error("No text found");
    }
  };

  const notify = () => {
    if (bodyContent) {
      toast.success("Copied Successfully.");
      mixpanel.track("Clicked on copy.", {});
    }
  };

  const slides = [
    {
      image:
        "https://ocreditor.com/blog/wp-content/uploads/2022/11/jpg-to-text-converter-1024x556.jpg",
      title: "Jpg To Text Converter | Recognize Text With OCR",
      description: "November 15, 2022",
      link: "https://ocreditor.com/blog/jpg-to-text-converter/",
    },
    {
      image:
        "https://ocreditor.com/blog/wp-content/uploads/2022/11/What-is-online-ocr-1024x556.jpg",
      title: "Free Online OCR Converter| Extract Text From Image Easily",
      description: "November 15, 2022",
      link: "https://ocreditor.com/blog/online-ocr-converter/",
    },
    {
      image:
        "https://ocreditor.com/blog/wp-content/uploads/2022/11/copy-text-from-pdf-1024x556.jpg",
      title: "Copy Text From PDF With OCR Editor",
      description: "November 28, 2022",
      link: "https://ocreditor.com/blog/copy-text-from-pdf/",
    },
    //  {
    //     image: "https://ocreditor.com/blog/wp-content/uploads/2022/11/Copy-Text-From-YouTube-Video-1024x556.jpg",
    //     title: "Copy Text From YouTube Video With OCR Editor",
    //     description: "November 28, 2022",
    //     link: "https://ocreditor.com/blog/copy-text-youtube-video/"
    // },
  ];

  let collapseMenuText = [
    "OCR Editor is an image-to-text converter that can extract any text from any image format.",
    "OCR editors are among the most accurate image-to-text extractors. Simply upload the image and download the text in doc, pdf, or txt format. Users can also install the OCR editor extension for image-to-text conversion.",
    "Yes. The online OCR editor extension is completely free to use.",
    "Yes, of course. OCR editor can convert all kinds of images into text with full accuracy and without any errors. ",
    "OCR Editor currently supports jpg, jpeg, and png image formats.",
  ];

  const toggleButton = () => {
    setIsOpen(!isOpen);
  };
  const showResult = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  let newDivSelector
  const hideModalContainer = () => {
    newDivSelector.addEventListener('click', function () {
      setModalVisible(false);
    })
  }

  const cancelBtnCreation = () => {
    var divEle = document.createElement('div')
    var iDiv = document.createElement('div');
    divEle.id = "modalDiv"
    iDiv.innerText = "X"
    iDiv.id = "newDiv"

    divEle.appendChild(iDiv)

    setTimeout(function () {
      let result = document.querySelector('#newDiv') ? "" : document.querySelector('.ant-modal-content')?.prepend(divEle)
      newDivSelector = document.querySelector('#modalDiv')
      if (newDivSelector) {
        hideModalContainer()
      }
    }, 300)

  }

  const uploadStatus = () => {
    setUploadStatus(true)
    setProgressBarStatus(30)
    setTimeout(() => {
      setTimeout(() => {
        setProgressBarStatus(100)
        setUploadText('uploaded')
        document.querySelector('.convertBtn').style.opacity = "1"
      }, 500)
      setProgressBarStatus(60)
    }, 1000)
  }

  const convertBtn = () => {
    setDisplayResultPage(true)
    setProgressBarStatus(50)

  }

  const uploadConvertStatus = () => {
    setUploadStatus(true)
    setProgressBarStatus(30)
    setTimeout(() => {
      setTimeout(() => {
        setProgressBarStatus(100)
        setUploadText('uploaded')
        document.querySelector('.convertBtn').style.opacity = "1"
      }, 500)
      setProgressBarStatus(60)
    }, 1000)
  }

  const formatText = (textArray) => {
    if (!textArray || textArray.length === 0) {
      return "";
    }
  
    const formattedText = textArray.join('\n').replace(/,/g, '');
  
    return formattedText;
  }
  return (
    <div className="ocr">
      <div className="background">
        <h1>Online OCR Editor for Converting Image Into Text</h1>
        <h3>Supports all kind of image formats. Eg: JPG, PNG, etc</h3>
      </div>

      <div className="ocrWrapper">
        {bodyContent ? (
          <SideBarAd adMarginTop="-266px" adMarginRight="90px" />
        ) : (
          <SideBarAd adMarginTop="-266px" adMarginRight="90px" />
        )}
        <div className="fullOcrContainer">
          <div className="ocrContainer">
            <div>
              <Toaster position="bottom-right" />
            </div>
            {displayResult ? (
              <OcrUploadSection
                uploadConvertStatus={uploadConvertStatus}
                onChange={getData}
                imgName={getImg}
                processingText={getProcessingText}
                setProgressStatus={setProgressStatus}
                setBodyContent={setBodyContent}
                setImg={setImg}
                uploadStatus={uploadStatus}
              />
            ) :
              (!displayResultPage &&
                (
                  <div>
                    <img className="thumb" id="uploadImg" src={img} />
                    <div className="uploadContainer">
                      <Progress percent={progressBarStatus} size="small" id="progressContent"></Progress>
                      <p className="uploadStatus">{uploadText}</p>
                    </div>
                    <div className="adContainer">

                      <div className="adSection">
                        <MiddlePageAd />
                      </div>
                      <div className="convertBtn"><button onClick={() => { convertBtn() }}>Get Text</button></div>
                      <div className="displayText">Supports all kind of image formats. Eg: JPG, PNG, etc</div>
                    </div>

                  </div>
                )
              )
            }

            {displayResultPage &&
              <div>
                <div className="modalWrapper">
                  <Modal
                    maskStyle={{
                      opacity: 0.5
                    }}
                    visible={modalVisible}
                    onCancel={handleCancel}
                  >
                    <p className="modalText">Recognized Successfully !!!</p>
                    <div className="progressBar">
                      <Progress percent={progressStatus} size="small" id="modalProgress"></Progress>
                    </div>

                    <ModalAd />
                    <div className="showBtn"> <button onClick={() => { setModalVisible(false); }} className="show-result-btn">Show results</button></div>
                  </Modal>
                </div>
                {modalVisible && cancelBtnCreation()}

                <OcrProcessSection
                  img={img}
                  name={ImageName}
                  size={ImageSize}
                  divShow={processingTextContain}
                  setProgressStatus={setProgressStatus}
                  pValue={progressStatus}
                  content={getBodycontent}
                />

              </div>
            }
            {displayResultPage &&
              <div>
                {bodyContent ? <p id="resultText">Results :</p> : ""}
                {bodyContent ? (
                  <div className="outputDiv">
                            <textarea
                  id="ocrTextArea"
                  defaultValue={formatText(bodyContent)}
                  style={{ padding: "10px" }}
                ></textarea>
                  </div>
                ) : (
                  ""
                )}
              </div>
            }

            {displayResultPage && <div>
              {bodyContent ? (
                <div id="resultFooter">
                  <ResultPageAd />
                  <CopyToClipboard text={bodyContent}>
                    <img
                      src={copyIcon}
                      onClick={notify}
                      id="copybtn"
                      style={
                        bodyContent
                          ? {
                            opacity: "1",
                          }
                          : {
                            opacity: "0.4",
                          }
                      }
                    />
                  </CopyToClipboard>
                  <img
                    src={trashIcon}
                    onClick={deleteText}
                    id="trashBtn"
                    style={
                      bodyContent
                        ? {
                          opacity: "1",
                        }
                        : {
                          opacity: "0.4",
                        }
                    }
                  />
                  <span id="downloadBtn" onClick={toggleButton}>
                    <div className="selector">
                      <div id="selectField">
                        <span id="selectText">Download</span>
                        <img src={arrowDown} />
                      </div>
                      {isOpen && (
                        <ul id="list">
                          <li
                            className="options"
                            id="docIcon"
                            onClick={generateDoc}
                          >
                            <img src={docIcon} />
                            <p>.doc</p>
                          </li>
                          <li className="options" onClick={generateTxt}>
                            <img src={txtIcon} />
                            <p>.txt</p>
                          </li>
                          <li className="options" onClick={generatePdf}>
                            <img src={pdfIcon} />
                            <p>.pdf</p>
                          </li>
                        </ul>
                      )}{" "}
                    </div>
                  </span>
                  <ResultPageAdComponent />
                </div>
              ) : (
                ""
              )}
            </div>
            }
            {" "}


          </div>

          <div className="howToUseSection" id="use">
            <h2 className="useText">How To Use Online OCR Editor</h2>
            <div className="howToUseCardDiv">
              <UseCard
                image={galleryImage}
                useContainerheight="230px"
                text={
                  "Upload the image file that you want to convert to text, or else paste the image URL"
                }
              />
              <UseCard
                image={version}
                useContainerheight="230px"
                text={
                  "Copy or edit the text after extracting it, or else download the text in.doc or.txt format or save it in PDF format."
                }
              />
              <UseCard
                image={extension}
                useContainerheight="230px"
                text={
                  'In OCR editor extension, select "Detect from Webpage" else choose the "Upload image" option '
                }
              />
            </div>
          </div>

          {/* feature section///////////////// */}
          <div className="featureSection">
            <h2 className="featureTitle">Key Features Of OCR Text Extractor</h2>
            <div className="featureWrapper">
              <div className="feature">
                <div className="featureImgContainer">
                  <img src={Feature1} />
                </div>
                <div className="featureContent">
                  <h3>Text Extraction from Low Resolution Images & Videos</h3>
                  <p>
                    You no longer have to worry about whether text can be
                    extracted from blurry or low-quality images and videos
                    because Online OCR Text Extractor can easily detect text on
                    low-resolution images and videos.
                  </p>
                </div>
              </div>
              <div className="feature">
                <div className="featureContent">
                  <h3>Copy Text from Image & Video with One click</h3>
                  <p>
                    Once you have selected the text in the desired area on an
                    image or video, you just need to click on the copy to
                    clipboard option to copy the entire text.
                  </p>
                </div>
                <div className="featureImgContainer">
                  <img src={Feature2} />
                </div>
              </div>
              <div className="feature">
                <div className="featureImgContainer">
                  <img src={Feature3} />
                </div>
                <div className="featureContent">
                  <h3>Supports Multiple Language Text Extraction</h3>
                  <p>
                    It does not matter which language you are comfortable with;
                    this OCR tool can convert any online or saved image to text
                    in no time.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* why section/////////////// */}
          <div className="WhySection" id="use">
            <h2 className="useText">Why Image to Text Converter?</h2>
            <div className="WhySectionCardDiv">
              <Use
                image={WhyImg1}
                title={"Saves Time"}
                text={
                  "Time usage is directly proportional to productivity. Online OCR image-to-text converter extension can help you increase your work efficiency because you do not need to retype the same words again. Copy all the text on any image with a single click only."
                }
              />
              <Use
                image={WhyImg2}
                title={"Extract Important Text from Documents"}
                text={
                  "OCR Editor makes it super easy to extract important text from copy-protected online documents."
                }
              />
              <Use
                image={WhyImg3}
                title={"Convert Social Media Images to text"}
                text={
                  "We see many interesting images on social media pages like Facebook, Instagram, Twitter, and others. These media files can be transformed into text with this free online OCR converter, and you can make use of this text wherever you want."
                }
              />
            </div>
          </div>

          {/* faq//////////////// */}
          <div className="faqSection">
            <h2 className="faqTitle">Faq's</h2>
            <div className="container">
              {/* <CollapseMenu /> */}
              <CollapseMenu
                faqText="What is Online OCR editor?  "
                faqAns={collapseMenuText[0]}
              />
              <CollapseMenu
                faqText="How do I extract text from image? "
                faqAns={collapseMenuText[1]}
              />
              <CollapseMenu
                faqText="Is the OCR Editor Chrome Extension free to use? "
                faqAns={collapseMenuText[2]}
              />
              <CollapseMenu
                faqText="Is this image-to-text converter 100% accurate? "
                faqAns={collapseMenuText[3]}
              />
              <CollapseMenu
                faqText="Which image formats are supported by the online OCR image-to-text converter?"
                faqAns={collapseMenuText[4]}
              />
            </div>
          </div>

          {/* blogsss//////////////////// */}
          <div className="blogSection">
            <h2 className="blogTitle">Blogs</h2>
            <p id="viewAllBtn">
              <a href="https://ocreditor.com/blog/">View all</a>
            </p>
            <div className="cardSliderDiv">
              <ReactCardSlider1 slides={slides} />
            </div>
          </div>


          <div className="extensionSection" id="getExtension">
            <div className="extensionContainer">
              <div className="ext-fir">
                <h2 className="extensionDesc">
                  Get OCR editor browser extension
                </h2>
                <div className="extensionWrapper">
                  <div className="extensionNote">
                    <img src={checkedIcon} />
                    <p>Completely Free</p>
                  </div>
                  <div className="extensionNote">
                    <img src={checkedIcon} />
                    <p>Easy to access</p>
                  </div>
                  <div className="extensionNote">
                    <img src={checkedIcon} />
                    <p>Detect from image and videos</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="extensionImg">
                  <a
                    href="https://chrome.google.com/webstore/detail/ocr-editor-text-from-imag/ihnfmldlpdipgnliolhfffenpcmjgnif"
                    target={"_blank"}
                  >
                    <div
                      className="extensionDiv"
                      onClick={() => {
                        mixpanel.track("Clicked on Chrome WebStore ", {});
                      }}
                    >
                      <img src={chromePng} />
                    </div>
                  </a>
                  <a href="#">
                    <div
                      className="extensionDiv"
                      onClick={() => {
                        mixpanel.track(
                          "Clicked on Microsoft Edge WebStore ",
                          {}
                        );
                      }}
                    >
                      <img src={edgePng} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>

        {bodyContent ? (
          <SideBarAd adMarginTop="-266px" adMarginLeft="90px" />
        ) : (
          <SideBarAd adMarginTop="-266px" adMarginLeft="90px" />
        )}
      </div>

      <div className="Footer">
        <Footer />
      </div>

      {/* FOOTER AD  */}
      <FooterAd />
    </div>
  );
};
export default Ocr;
