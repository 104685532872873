import UserContext from '../Usercontext';
import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import './Nav.css'
import logo from "../images/Logo.png"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import mixpanel from 'mixpanel-browser'
import google from "../images/google.png"

 

const NavMain = (props) => {
  // const [flag, setFlag] = useState(1)
  const {bodyContent, setBodyContent, displayResult, setDisplayResult, progressStatus, setProgressStatus } = useContext(UserContext)
  const goHome = () => {
    setDisplayResult(true)
    setProgressStatus(0)
    setBodyContent("")
  }


  return (
    <Navbar expand="lg" className='navContainer'>
      <Container className='navWrapper'>
        <Navbar.Brand href="#home"><Link to="/"  >
          <img src={logo} alt='img' className='home-btn' onClick={goHome} /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className='nav-item' href="/feedback">Feedback</Nav.Link>
            <Nav.Link className='nav-item' href="/contact">Contact</Nav.Link>
            <Nav.Link className='nav-item' href="https://ocreditor.com/blog/" onClick={() => { mixpanel.track('Clicked on Blogs',{ })}}>Blog</Nav.Link>

  
             <Nav.Link className='nav-item getExtension-container'><span className='getExtension' ><img className='get-img' src={google} onClick={() => { 
          window.open("https://chrome.google.com/webstore/detail/ocr-editor-text-from-imag/ihnfmldlpdipgnliolhfffenpcmjgnif")
          }}/></span></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


  )
}
export default NavMain;