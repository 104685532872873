import "./UseOCR.css";
import galleryImage from "../../images/image-gallery.svg";
import version from "../../images/version.svg";
import extension from "../../images/extension.svg";

const UseOCR = (props) => {
  return (
    <div className="second-cont">
      <h2 className="useText">How To Use Online OCR Editor</h2>
      <div className="card-cont">
        <div className="card first-card">
          <div className="card-img">
            <img src={galleryImage} className="img-fir" />
          </div>
          <div className="card-text">
            Upload the image file that you want to convert to text, or else
            paste the image URL
          </div>
        </div>
        <div className="card first-card">
          <div className="card-img">
            <img src={version} className="img-fir" />
          </div>
          <div className="card-text">
            Copy or edit the text after extracting it, or else download the text
            in.doc or.txt format or save it in PDF format.
          </div>
        </div>
        <div className="card first-card">
          <div className="card-img">
            <img src={extension} className="img-fir" />
          </div>
          <div className="card-text">
            In OCR editor extension, select "Detect from Webpage" else choose
            the "Upload image" option
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseOCR;
