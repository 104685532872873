import CollapseMenu from "../../helper/CollapseMenu";
import "./Faq.css";

let collapseMenuText = [
  "OCR Editor is an image-to-text converter that can extract any text from any image format.",
  "OCR editors are among the most accurate image-to-text extractors. Simply upload the image and download the text in doc, pdf, or txt format. Users can also install the OCR editor extension for image-to-text conversion.",
  "Yes. The online OCR editor extension is completely free to use.",
  "Yes, of course. OCR editor can convert all kinds of images into text with full accuracy and without any errors. ",
  "OCR Editor currently supports jpg, jpeg, and png image formats.",
];

const Faq = (props) => {
  return (
    <div className="faqSection">
      <h2 className="faqTitle">Faq's</h2>
      <div className="container">
        <CollapseMenu
          faqText="What is Online OCR editor?  "
          faqAns={collapseMenuText[0]}
        />
        <CollapseMenu
          faqText="How do I extract text from image? "
          faqAns={collapseMenuText[1]}
        />
        <CollapseMenu
          faqText="Is the OCR Editor Chrome Extension free to use? "
          faqAns={collapseMenuText[2]}
        />
        <CollapseMenu
          faqText="Is this image-to-text converter 100% accurate? "
          faqAns={collapseMenuText[3]}
        />
        <CollapseMenu
          faqText="Which image formats are supported by the online OCR image-to-text converter?"
          faqAns={collapseMenuText[4]}
        />
      </div>
    </div>
  );
};

export default Faq;
