import ReactCardSlider1 from '../../helper/ReactCardSlider1';
import "./Blogs.css";
const slides = [
    {
        image: "https://ocreditor.com/blog/wp-content/uploads/2022/11/jpg-to-text-converter-1024x556.jpg",
        title: "Jpg To Text Converter | Recognize Text With OCR",
        description: "November 15, 2022",
        link: "https://ocreditor.com/blog/jpg-to-text-converter/"
    }, {
        image: "https://ocreditor.com/blog/wp-content/uploads/2022/11/What-is-online-ocr-1024x556.jpg",
        title: "Free Online OCR Converter| Extract Text From Image Easily",
        description: "November 15, 2022",
        link: "https://ocreditor.com/blog/online-ocr-converter/"
    },
    {
        image: "https://ocreditor.com/blog/wp-content/uploads/2022/11/copy-text-from-pdf-1024x556.jpg",
        title: "Copy Text From PDF With OCR Editor",
        description: "November 28, 2022",
        link: "https://ocreditor.com/blog/copy-text-from-pdf/"
    },

]

const Blogs = (props) => {
  return (
    <div className='blogSection'>
    <h2 className='blogTitle'>Blogs</h2>
    <p id='viewAllBtn'>
        <a href='https://ocreditor.com/blog/'>View all</a>
    </p>
    <div className='cardSliderDiv'>
        <ReactCardSlider1 slides={slides} />
    </div>

</div>
  );
};

export default Blogs;
