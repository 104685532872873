
import { useState, useEffect, useContext } from "react"
// import { Progress } from "antd"
import ProgressBar from "@ramonak/react-progress-bar"
import "./OcrProcessSection.css"
import CountUp from "react-countup"
import successIcon from "../images/sucessIcon.svg"
import byteSize from "byte-size"
import UserContext from '../Usercontext';
import magnifierImg from "../images/magnifying-glass.svg"


const OcrProcessSection = (props) => {
    const [recognizerText, setrecognizerText] = useState("Recognizing text...")
    const { displayResult, setDisplayResult, progressStatus, setProgressStatus, displayResultPage, setDisplayResultPage } = useContext(UserContext)
    const goHome = () => {
        setDisplayResult(true)
        setDisplayResultPage(false)
        setProgressStatus(0)
        props.content("")
    }

    let text = (props.name.length > 50) ? props.name.substring(0, 50) + "..." : props.name;

    useEffect(() => {
        if (props.divShow) {
            setTimeout(() => {
                setrecognizerText("Recognized successfully")
            }, 2000);
        }

    });

    return (
        <div className="processConatiner">
            <div className="thumbProcessHeader">
                <div className="thumb">
                    <img src={props.img} alt="thumb" />
                </div>
                <div className="recognizerTextDiv">
                    <p>{recognizerText}</p>
                    <ProgressBar completed={props.pValue} bgColor="#279CF0" baseBgColor="#D9D9D9" isLabelVisible={false} />
                    {/* <CountUp start={0} end={100} duration={2} /><span>%</span> */}
                </div>
            </div>
            <div className="fileDesc">
                <p id="file-tex">File Name : {text || "N/A"}</p>
                <p>File Size :{byteSize(props.size).value} {byteSize(props.size).unit}</p>
                <div id="reDetect" onClick={goHome}>
                    <span><img src={magnifierImg} /></span> <p>Detect New</p>
                </div>
            </div>
        </div>
    )
}
export default OcrProcessSection;