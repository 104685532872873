import { useEffect } from "react";
import "./middlePageAd.css";

const MiddlePageAd = (props) => {
    //  let adsbygoogle
    useEffect(() => {
        const id = setInterval(triggerInterval, 100);
    
        function triggerInterval() {
            const adCont = document.querySelector('.adsbygoogle');
    
            if (adCont) {
                document.querySelector('.ad-cont').querySelector('.adsbygoogle').style.height = "73px";
                document.querySelector('.adSection').style.height = "73px"
                document.querySelector('.adContainer').style.height = "108px"
                document.querySelector('#aswift_2_host').style.height = "73px"
                document.querySelector('#aswift_2').style.height = "73px"
                clearInterval(id);
            }
        }
    
        window.addEventListener('load', function () {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        });
    
        return () => {
            clearInterval(id);
        };
    }, []);
    

    return (
        <div className="ad-cont">
            <ins
                className="adsbygoogle"
                data-ad-client="ca-pub-7894154869919315"
                data-ad-format="auto"
                data-ad-slot="7987832891"
                data-full-width-responsive="true"
                style={{ display: "inline-block", width: "802px", height: "20px", position: "sticky" }}
            />
        </div>
    );
};
export default MiddlePageAd;
