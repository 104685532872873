import Use from "./../Use";
import WhyImg1 from "../../images/whyImg1.svg";
import WhyImg2 from "../../images/whyImg2.svg";
import WhyImg3 from "../../images/whyImg3.svg";
import "./SecondCard.css";

const SecondCard = (props) => {
  return (
    <div className="WhySection" id="use">
      <h2 className="useText">Why Image to Text Converter?</h2>
      <div className="WhySectionCardDiv">
        <Use
          image={WhyImg1}
          title={"Saves Time"}
          text={
            "Time usage is directly proportional to productivity. Online OCR image-to-text converter extension can help you increase your work efficiency because you do not need to retype the same words again. Copy all the text on any image with a single click only."
          }
        />
        <Use
          image={WhyImg2}
          title={"Extract Important Text from Documents"}
          text={
            "OCR Editor makes it super easy to extract important text from copy-protected online documents."
          }
        />
        <Use
          image={WhyImg3}
          title={"Convert Social Media Images to text"}
          text={
            "We see many interesting images on social media pages like Facebook, Instagram, Twitter, and others. These media files can be transformed into text with this free online OCR converter, and you can make use of this text wherever you want."
          }
        />
      </div>
    </div>
  );
};

export default SecondCard;
