import { useState } from "react";

import "./Feedback.css"
import ComingSoon from "./ComingSoon";
import Footer from "./Footer";
import feedbackIcon from "../images/feedbackIcon.png"
import toast, { Toaster } from 'react-hot-toast';


const Feedback = () => {
    const [feedback, setFeedback] = useState('Webapp');

    const [userName, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [textareaText, setTextareaText] = useState('');

    let feedbackData = {
        "source": feedback,
        "name": userName,
        "email": email,
        "phone": number,
        "feedback": textareaText
    }


    const handleChange = (e) => {
        setUsername(e.target.value)
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const handleNumberChange = (e) => {
        if (e.target.value.length < 11) {

            setNumber(e.target.value)
        }

    }
    const handleTextAreaChange = (e) => {
        setTextareaText(e.target.value)
    }
    var invalidChars = [
        "-",
        "+",
        "e",
        "E"
    ];
    const invalidCharacter = (e) => {

        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    }
    const handleSubmit = (e) => {
        // e.preventDefault();
        if (number.length === 10) {
            sendData(feedbackData)
            toast.success('Feedback Sent')

            e.preventDefault();
        } else {
            sendData(feedbackData)
            toast.success('Feedback Sent')

            e.preventDefault();
        }

    }
    const sendData = (data) => {
        fetch('https://backend.ocreditor.com/api/website/feedback/add', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }






    return (
        <div className="feedbackContainer">
            <div><Toaster position="bottom-right" /></div>

            <h1 id="feedbackHeader">Feedbacks help us to improve !</h1>
            <h3 id="feedbackDesc">Please share your thoughts, suggestions, problems etc</h3>
            <div className="contentContainer">
                <div className="feedbackFormContainer">
                    <form onSubmit={(e) => { handleSubmit(e) }}>
                        <div className="formDiv" id="formHeader">
                            <span id="formTitle">This feedback is for:</span>

                            <input type="radio" id="webapp" name="radioSelection" value="Webapp" defaultChecked onClick={(e) => { setFeedback("Webapp") }} /> Webapp
                            {/* <label for="webapp">Webapp</label> */}

                            <input type="radio" id="extension" name="radioSelection" value="Extension" onClick={(e) => { setFeedback("Extension") }} /> Extension
                            {/* <label for="extension">Extension</label> */}

                            <input type="radio" id="both" name="radioSelection" value="Both" onClick={(e) => { setFeedback("Both") }} /> Both
                            {/* <label for="both">Both</label> */}
                        </div>

                        <div className="formDiv">
                            <input type="text" className="inputField" placeholder="Name*" required value={userName} onChange={(e) => { handleChange(e) }} />
                        </div>

                        <div className="formDiv">
                            <input type="email" className="inputField" placeholder="Email*" required value={email} onChange={(e) => { handleEmailChange(e) }} />
                        </div>

                        <div className="formDiv">
                            <input type="number" maxLength={10} className="inputField" placeholder="Phone" value={number} onChange={(e) => { handleNumberChange(e) }} onKeyDown={(e) => { invalidCharacter(e) }} />
                        </div>

                        <div className="formDiv" id="textArea">
                            {/* <input type="textarea" className="inputField"  placeholder="feedback"  value={number} onChange={(e) => { handleNumberChange(e) }} /> */}
                            <textarea placeholder="feedback" onChange={(e) => { handleTextAreaChange(e) }} />
                        </div>

                        <div className="formDiv">
                            <input id="submit" type="submit" value="Submit" />

                        </div>

                    </form>
                </div>
                <div className="feedbackIconContainer">
                    <img src={feedbackIcon} />
                </div>

            </div>



            <Footer />
        </div>

    )
}
export default Feedback;