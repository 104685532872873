import Feature1 from "../../images/feature1.svg"
import Feature2 from "../../images/feature2.svg"
import Feature3 from "../../images/feature3.svg"
import "./FeaturesOCR.css";

const FeaturesOCR = (props) => {
  return (
    <div className='featureSection'>
    <h2 className='featureTitle'>Key Features Of OCR Text Extractor</h2>
    <div className="featureWrapper">
        <div className='feature'>
            <div className='featureImgContainer'>
                <img src={Feature1} />
            </div>
            <div className='featureContent'>
                <h3>Text Extraction from Low Resolution Images & Videos</h3>
                <p>You no longer have to worry about whether text can be extracted from blurry or low-quality images and videos because Online OCR Text Extractor can easily detect text on low-resolution images and videos.</p>
            </div>
        </div>
        <div className='feature'>
            <div className='featureContent'>
                <h3>Copy Text from Image & Video with One click</h3>
                <p>Once you have selected the text in the desired area on an image or video, you just need to click on the copy to clipboard option to copy the entire text.</p>
            </div>
            <div className='featureImgContainer'>
                <img src={Feature2} />
            </div>
        </div>
        <div className='feature'>
            <div className='featureImgContainer'>
                <img src={Feature3} />
            </div>
            <div className='featureContent'>
                <h3>Supports Multiple Language Text Extraction</h3>
                <p>It does not matter which language you are comfortable with; this OCR tool can convert any online or saved image to text in no time.</p>
            </div>
        </div>
    </div>
</div>
  );
};

export default FeaturesOCR;
