import { useEffect } from "react"


const SquareAds = () => {
    // let adsbygoogle    
    useEffect(()=>{
        (window.adsbygoogle = window.adsbygoogle || []).push({ })
    }, [])

    return (
        <div>
             <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-7894154869919315"
                data-ad-slot="7979229692"
                data-ad-format="auto"
                data-full-width-responsive="true" />        
        </div>
    )
}
export default SquareAds;

