import "./Footer.css"
import logo from "../images/Logo.png"
import { useState } from "react"
import { Link } from 'react-router-dom'
import toast, { Toast } from "react-hot-toast"
import mixpanel from "mixpanel-browser"



const Footer = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const subscribed=()=>{
        if(email !="" ){
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if(email.match(validRegex)){
                toast.success("Subscribed Successfully")
            }else{
                toast.error("Please enter correct Email")
            }

        }
        
    }
    return (
        <div className="footerMain">
            <div className="footerContainer">
                <div className="footerDescription">
                    <div className="footerLogo">
                        <img src={logo} />
                    </div>
                    <div className="description">
                        <p>Online OCR editor is a quick and accurate tool for converting images into text. It can extract text from jpg,  jpeg, and png images.</p>
                    </div>
                </div>
                <div className="footerLinks">
                    <p className="linkTitle">Quick links</p>
                    <div className="linkAddress">
                        <Link to="/feedback">Feedback</Link>
                        <Link to="/contact">Contact</Link>
                        <a href="https://ocreditor.com/blog/" onClick={() => { mixpanel.track('Clicked on Blogs',{ })}}>Blog</a>
                    </div>
                </div>
                <div className="footerExtension">
                    <p className="linkTitle">Subscribe</p>
                    <span>Get updates, Bug fixes & more</span>
                    <div className="subscribeInputDiv">
                        <input type="email" id="subscrbeInput" placeholder="Email" required value={email} onChange={(e) => { handleEmailChange(e) }}/>
                        <div className="subscribeBtn" onClick={subscribed} >subscribe</div>

                    </div>

                </div>

            </div>
            <p className="copyright">© 2022 <span>OCReditor</span> | All rights reserved</p>
        </div>

    )
}
export default Footer;