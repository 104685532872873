import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "../Usercontext";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import ProgressBar from "@ramonak/react-progress-bar";
import { Link, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Languages from "../helper/Languages";
import { jsPDF } from "jspdf";
import dateTime from "date-time";
import mixpanel from "mixpanel-browser";

import copyIcon from "../images/copyIcon.svg";
import arrowDown from "../images/arrowDown.svg";
import docIcon from "../images/doc.svg";
import txtIcon from "../images/txt.svg";
import pdfIcon from "../images/pdf.svg";
import load from "../images/load.gif";
import magnifierImg from "../images/magnifying-glass.svg";

import Footer from "./Footer";
import UseOCR from "./firstCard/UseOCR";
import FeaturesOCR from "./featuresOCR/FeaturesOCR";
import SecondCard from "./secondCard/SecondCard";
import Faq from "./faqSection/Faq";
import Blogs from "./blogs/Blogs";
import SideBarAd from "./adsSection/SideBarAd";
import FooterAd from "./adsSection/FooterAd";
import "./ResultsPage.css";

var Buffer = require("buffer/").Buffer;

const ResultsPage = (props) => {
  //GETTING URL
  let str;
  const location = useLocation();

  if (location.search === "") {
    str = location.pathname.split("/")[2];
  } else if (location.search.length != 0) {
    str = location.search.substring(4);
  }

  const [data, setData] = useState("");
  const [description, setDescription] = useState("");
  const [resImage, setResImage] = useState("");
  const [slider, setSlider] = useState(20);
  const [value, setValue] = useState("");

  const { isOpen, setIsOpen } = useContext(UserContext);
  let date = dateTime();
  const handleChange = (event) => {
    setValue(String(event.target.value));

    fetch("https://backend.ocreditor.com/getData", {
      method: "POST",
      body: JSON.stringify({
        data: description,
        language: event.target.value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDescription(data.sendRes);
        toast.success("Language changed.");
      });

    mixpanel.track("Clicked on Language Translation ", {});
  };

  const generateTxt = () => {
    let text = document.getElementById("copy").innerHTML;
    if (text) {
      const blob = new Blob([text], { type: "text/plain" });
      if (window.navigator.msSaveOrOpenBlob && text.length > 0) {
        window.navigator.msSaveBlob(blob, "speech");
      } else {
        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = "OCR " + date;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
      toast.success("Downloaded Text");
      mixpanel.track("Clicked on Download - TEXT ", {});
    } else {
      toast.error("No text found");
    }
  };
  const generateDoc = () => {
    let text = document.getElementById("copy").innerHTML;
    if (text) {
      const blob = new Blob([text], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      if (window.navigator.msSaveOrOpenBlob && text.length > 0) {
        window.navigator.msSaveBlob(blob, "speech");
      } else {
        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = "OCR " + date;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }

      toast.success("Downloaded Document");
      mixpanel.track("Clicked on Download - DOC ", {});
    } else {
      toast.error("No text found");
    }
  };

  const generatePdf = () => {
    const doc = new jsPDF();

    let text = document.getElementById("copy").innerHTML;
    if (text) {
      doc.text(text, 10, 10);
      doc.save(`OCR ${date}.pdf`);
      toast.success("Downloaded pdf");
      mixpanel.track("Clicked on Download - PDF ", {});
    } else {
      toast.error("No text found");
    }
  };

  const notify = async () => {
    if (description) {
      let copyText = document.getElementById("copy").innerHTML;
      try {
        await navigator.clipboard.writeText(copyText);
        mixpanel.track("Clicked on Copy", {});
      } catch (err) {
        // console.error("Failed to copy: ", err);
      }
      toast.success("Copied Successfully.");
    } else {
      toast.error("No text found");
    }
  };

  const toggleButton = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    //RESULT ID

    const url = `https://backend.ocreditor.com/api/image/text/${str}`;
    // const url = `http://localhost:8081/api/image/text/${str}`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);

        const json = await response.json();
        // console.log(json,'json hjson from ')

        if ((await json.success) === false) {
          window.open("https://ocreditor.com/", "_self");
        } else {
          setData(json);

          let base64data = new Buffer(json.data.image).toString("base64");

          let source = `data:image/png;base64,${base64data}`;

          setResImage(source);

          setDescription(json.data.result);

          setTimeout(() => {
            let ele = document.getElementById("res-copy");
            if (ele) {
              ele.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }, 500);

          setSlider(100);

          mixpanel.track("Page redirected Successfully.", {});
        }
      } catch (error) {
        // console.log("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="parent">
        <div className="backgroundd"></div>
        <div>
          <Toaster position="bottom-right" />
        </div>
        <div className="main">
          <div className="first-cont">
            <SideBarAd adMarginRight="66px" />
            <div className="inner-cont">
              <div className="heading-div">
                <h1>Online OCR Editor for Converting Image Into Text</h1>
                <h3>Supports all kind of image formats. Eg: JPG, PNG, etc.</h3>
              </div>
              {data === "" ? (
                <div className="waiting-div">
                  <div className="loading-img">
                    <img src={load} alt="" className="load" />
                  </div>
                  <div className="loading-animation">
                    <div className="load-text-div">
                      <div className="load-text-rec">Recognizing Text...</div>
                    </div>

                    <ProgressBar
                      completed={slider}
                      bgColor="#279CF0"
                      baseBgColor="#D9D9D9"
                      isLabelVisible={false}
                    />
                  </div>
                </div>
              ) : (
                <div className="result-div">
                  <div className="res-div-first">
                    <div>
                      <img src={resImage} alt="" className="load" />
                    </div>
                    <div className="loading-animation">
                      <div className="load-text-div">
                        <div className="load-text-rec">
                          Recognized successfully
                        </div>
                      </div>

                      <ProgressBar
                        completed={slider}
                        bgColor="#279CF0"
                        baseBgColor="#D9D9D9"
                        isLabelVisible={false}
                      />
                    </div>
                  </div>
                  <Link to="/" id="reDetect" className="redirect">
                    <span>
                      <img src={magnifierImg} className="mag" />
                    </span>
                    <div className="detect-now">Detect Now</div>
                  </Link>
                  <div className="result-container">
                    <div className="result-area">
                      <div className="result-head">
                        <div className="res-div-fir">Results:</div>
                        {/* start */}

                        <Box sx={{ minWidth: 120 }} className="lang-main">
                          <div className="lan-text">Translates to:</div>
                          <FormControl className="lang-parent">
                            <InputLabel id="demo-simple-select-label">
                              Select Language
                            </InputLabel>

                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={value}
                              label="Select Language"
                              onChange={handleChange}
                            >
                              {Languages.length &&
                                Languages.map((item, index) => {
                                  return (
                                    <MenuItem
                                      className="list"
                                      value={item.key}
                                      key={index}
                                    >
                                      {item.language}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>

                        {/* end */}
                      </div>
                      <div className="result" id="res-copy">
                        <div
                          style={{
                            width: "100%",
                            height: "30px",
                            position: "sticky",
                            top: "0px",
                            zIndex: 99,
                            background: "#ffffff",
                          }}
                        ></div>
                        <div
                          id="copy"
                          className="res-divv"
                          contentEditable="true"
                          suppressContentEditableWarning="true"
                        >
                          {description}
                        </div>
                        <div
                          style={{
                            width: " 100%",
                            height: " 32px",
                            position: "sticky",
                            bottom: "-22px",
                            zIndex: 99,
                            background: "rgb(255, 255, 255)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="adFooter">
                    <div className="result-footer">
                      <div className="addd">

                      </div>
                      <div className="res-footer-flex">
                        <img
                          src={copyIcon}
                          onClick={notify}
                          id="copybtn"
                          style={
                            description ? { opacity: "1" } : { opacity: "0.4" }
                          }
                        />

                        <span id="downloadBtn" onClick={toggleButton}>
                          <div className="selector">
                            <div id="selectField">
                              <span id="selectText">Download</span>
                              <img src={arrowDown} />
                            </div>
                            {isOpen && (
                              <ul id="list">
                                <li
                                  className="options"
                                  id="docIcon"
                                  onClick={generateDoc}
                                >
                                  <img src={docIcon} />
                                  <p>.doc</p>
                                </li>
                                <li className="options" onClick={generateTxt}>
                                  <img src={txtIcon} />
                                  <p>.txt</p>
                                </li>
                                <li className="options" onClick={generatePdf}>
                                  <img src={pdfIcon} />
                                  <p>.pdf</p>
                                </li>
                              </ul>
                            )}{" "}
                          </div>
                        </span>
                      </div>

                    </div>
                  </div>

                </div>
              )}

              {/* How To Use Online OCR Editor */}
              <UseOCR />

              {/* Key Features Of OCR Text Extractor */}
              <FeaturesOCR />

              {/* Why Image to Text Converter? */}
              <SecondCard />

              {/* Faq's Section  */}
              <Faq />

              {/* Blogs Section  */}
              <Blogs />
            </div>

            <SideBarAd adMarginLeft="66px" />
          </div>
        </div>
      </div>

      <div className="footer-div">
        {" "}
        <Footer />{" "}
      </div>

      {/* FOOTER AD  */}
      <FooterAd />
    </>
  );
};

export default ResultsPage;
