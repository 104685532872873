import { useEffect } from "react";
import "./resultPageComponentAd.css";

const ResultPageAdComponent = (props) => {
    useEffect(() => {
       window.addEventListener('load', function (){
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        })
        let adCont = document.querySelector('.adsbygoogle')

        const id = setInterval(triggerInterval, 100)

        function triggerInterval() {
            if (adCont) {
                document.querySelector('.second-ad-resultpage').querySelector('.adsbygoogle').style.width = "100px";
                document.querySelector('.second-ad-resultpage').querySelector('.adsbygoogle').style.height = "100px";
                clearInterval(id)
            }

        }


    return () => {
        clearInterval(id);
    };

    }, []);

    return (
        <div className="second-ad-resultpage">
            <ins
                className="adsbygoogle"
                data-ad-client="ca-pub-7894154869919315"
                data-ad-format="auto"
                data-ad-slot="7987832891"
                data-full-width-responsive="true"
                style={{ display: "inline-block", width: "100px", height: "50px", position: "sticky" }}
            />
        </div>
    );
};
export default ResultPageAdComponent;
