import { useEffect } from "react";

const HorizontalAds = () => {


    // let adsbygoogle   
    useEffect(()=>{
        (window.adsbygoogle = window.adsbygoogle || []).push({ })
    }, [])
    return (   

        <div>
             <ins class="adsbygoogle"
                style={{display:"block"}}
                data-ad-format="fluid"
                data-ad-layout-key="-e9+63+bn-fg-9d"
                data-ad-client="ca-pub-7894154869919315"
                data-ad-slot="3135178692" /> 
        </div>
    )
}
export default HorizontalAds;