import { useEffect } from "react";
import "./SideBarAd.css";

const SideBarAd = (props) => {
  //  let adsbygoogle
  useEffect(() => {
   window.addEventListener('load', function (){
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        })
    document.querySelector('.ad-contt').querySelector('.adsbygoogle').style.height = "73px";
    document.querySelector('.ad-contt').querySelector('.adsbygoogle').style.width = "73px";

  }, []);

  return (
    <div
      className="ad-contt"
      style={{
        marginRight: props.adMarginRight,
        marginLeft: props.adMarginLeft,
        marginTop: props.adMarginTop,
      }}
    >
      <ins
        className="adsbygoogle"
        data-ad-client="ca-pub-7894154869919315"
        data-ad-format="auto"
        data-ad-slot="7987832891"
        data-full-width-responsive="true"
        style={{ display: "inline-block", width: "120px", height: "515px", position: "sticky", top: "98px" }}
      />
    </div>
  );
};
export default SideBarAd;
