import "./UseCard.css"


const UseCard =(props) =>{
    return(
        <div className="UseContainer">
            <div className="cardImage">
                <img src={props.image} />
            </div>
            {props.title && <h3>{props.title}</h3>}
            <p>{props.text}</p>
        </div>
    )
}

export default UseCard;