import { useState } from "react"
import "./AdBoxSquare.css"

const AdBoxSquare = (props) => {



// console.log(props.Ads,"ads")

    return (
        <div className="AdContainer" style={{ height: props.adContainerHeight, width: props.adContainerWidth, position: props.adContainerPosition, top: props.adContainerTop }}>
            {props.Ads}

        </div>
    )
}

export default AdBoxSquare;