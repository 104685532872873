import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useContext } from 'react';
import UserContext from './Usercontext';
import './App.css';
import NavMain from './components/Nav';
import Ocr from './components/Ocr';
import Use from './components/Use';
import mixpanel from 'mixpanel-browser';
import Feedback from './components/Feedback';
import Contact from './components/Contact';
import ResultsPage from './components/ResultsPage';
import AdBoxSquare from "./components/AdBoxSquare"
import UseCard from './components/UseCard';
import galleryImage from "../src/images/image-gallery.svg"
import workInProgress from "../src/images/work-in-progress.svg"
import textFont from "../src/images/text-font.svg"
import Footer from './components/Footer';



mixpanel.init(process.env.REACT_APP_PROJECT_TOKEN);

function App() {
  const [displayResult, setDisplayResult] = useState(true)
  const [progressStatus, setProgressStatus] = useState(1)
  const [bodyContent, setBodyContent] = useState("")
  const [togglerBtn, setTogglerBtn] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  const [displayResultPage, setDisplayResultPage] = useState(false)

  const toggler = () => {
    if (isOpen) {

      setIsOpen(false)
    }

  }

  return (
    <div className="App" onClick={toggler}>
      <UserContext.Provider value={{ displayResult, setDisplayResult, progressStatus, setProgressStatus, bodyContent, setBodyContent, isOpen, setIsOpen, displayResultPage, setDisplayResultPage }}>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7894154869919315"
          crossOrigin="anonymous"></script>
        <Router>

          <NavMain />


          <div className='body-container'>
            <Routes>
              <Route exact path="/" element={<Ocr downloadSwitch={togglerBtn} />} />
              <Route exact path="/feedback" element={<Feedback />} />
              <Route exact path="/contact" element={<Contact />} />
              <Route exact path="/result/*" element={<ResultsPage />} />
              <Route exact path="/results" element={<ResultsPage />} />

            </Routes>
          </div>



        </Router>
      </UserContext.Provider>


    </div>
  );
}

export default App;
