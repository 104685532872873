const languages = [
    {
        "key": "af",
        "language": "Afrikaans"
    },
    {
        "key": "sq",
        "language": "Albanian"
    },
    {
        "key": "am",
        "language": "Amharic"
    },
    {
        "key": "ar",
        "language": "Arabic"
    },
    {
        "key": "hy",
        "language": "Armenian"
    },
    {
        "key": "as",
        "language": "Assamese"
    },
    {
        "key": "ay",
        "language": "Aymara"
    },
    {
        "key": "az",
        "language": "Azerbaijani"
    },
    {
        "key": "bm",
        "language": "Bambara"
    },
    {
        "key": "eu",
        "language": "Basque"
    },
    {
        "key": "be",
        "language": "Belarusian"
    },
    {
        "key": "bn",
        "language": "Bengali"
    },
    {
        "key": "bho",
        "language": "Bhojpuri"
    },
    {
        "key": "bs",
        "language": "Bosnian"
    },
    {
        "key": "bg",
        "language": "Bulgarian"
    },
    {
        "key": "ca",
        "language": "Catalan"
    },
    {
        "key": "ceb",
        "language": "Cebuano"
    },
    {
        "key": "zh-CN",
        "language": "Chinese (Simplified)"
    },
    {
        "key": "zh-TW",
        "language": "Chinese (Traditional)"
    },
    {
        "key": "co",
        "language": "Corsican"
    },
    {
        "key": "hr",
        "language": "Croatian"
    },
    {
        "key": "cs",
        "language": "Czech"
    },
    {
        "key": "da",
        "language": "Danish"
    },
    {
        "key": "dv",
        "language": "Dhivehi"
    },
    {
        "key": "doi",
        "language": "Dogri"
    },
    {
        "key": "nl",
        "language": "Dutch"
    },
    {
        "key": "en",
        "language": "English"
    },
    {
        "key": "eo",
        "language": "Esperanto"
    },
    {
        "key": "et",
        "language": "Estonian"
    },
    {
        "key": "ee",
        "language": "Ewe"
    },
    {
        "key": "fil",
        "language": "Filipino (Tagalog)"
    },
    {
        "key": "fi",
        "language": "Finnish"
    },
    {
        "key": "fr",
        "language": "French"
    },
    {
        "key": "fy",
        "language": "Frisian"
    },
    {
        "key": "gl",
        "language": "Galician"
    },
    {
        "key": "ka",
        "language": "Georgian"
    },
    {
        "key": "de",
        "language": "German"
    },
    {
        "key": "el",
        "language": "Greek"
    },
    {
        "key": "gn",
        "language": "Guarani"
    },
    {
        "key": "gu",
        "language": "Gujarati"
    },
    {
        "key": "ht",
        "language": "Haitian Creole"
    },
    {
        "key": "ha",
        "language": "Hausa"
    },
    {
        "key": "haw",
        "language": "Hawaiian"
    },
    {
        "key": "he",
        "language": "Hebrew"
    },
    {
        "key": "hi",
        "language": "Hindi"
    },
    {
        "key": "hmn",
        "language": "Hmong"
    },
    {
        "key": "hu",
        "language": "Hungarian"
    },
    {
        "key": "is",
        "language": "Icelandic"
    },
    {
        "key": "ig",
        "language": "Igbo"
    },
    {
        "key": "ilo",
        "language": "Ilocano"
    },
    {
        "key": "id",
        "language": "Indonesian"
    },
    {
        "key": "ga",
        "language": "Irish"
    },
    {
        "key": "it",
        "language": "Italian"
    },
    {
        "key": "ja",
        "language": "Japanese"
    },
    {
        "key": "jv",
        "language": "Javanese"
    },
    {
        "key": "kn",
        "language": "Kannada"
    },
    {
        "key": "kk",
        "language": "Kazakh"
    },
    {
        "key": "km",
        "language": "Khmer"
    },
    {
        "key": "rw",
        "language": "Kinyarwanda"
    },
    {
        "key": "gom",
        "language": "Konkani"
    },
    {
        "key": "ko",
        "language": "Korean"
    },
    {
        "key": "kri",
        "language": "Krio"
    },
    {
        "key": "ckb",
        "language": "Kurdish"
    },
    {
        "key": "ckb",
        "language": "Kurdish (Sorani)"
    },
    {
        "key": "ky",
        "language": "Kyrgyz"
    },
    {
        "key": "lo",
        "language": "Lao"
    },
    {
        "key": "la",
        "language": "Latin"
    },
    {
        "key": "lv",
        "language": "Latvian"
    },
    {
        "key": "ln",
        "language": "Lingala"
    },
    {
        "key": "lt",
        "language": "Lithuanian"
    },
    {
        "key": "lg",
        "language": "Luganda"
    },
    {
        "key": "lb",
        "language": "Luxembourgish"
    },
    {
        "key": "mk",
        "language": "Macedonian"
    },
    {
        "key": "mai",
        "language": "Maithili"
    },
    {
        "key": "mg",
        "language": "Malagasy"
    },
    {
        "key": "ms",
        "language": "Malay"
    },
    {
        "key": "ml",
        "language": "Malayalam"
    },
    {
        "key": "mt",
        "language": "Maltese"
    },
    {
        "key": "mi",
        "language": "Maori"
    },
    {
        "key": "mr",
        "language": "Marathi"
    },
    {
        "key": "mni-Mtei",
        "language": "Meiteilon (Manipuri)"
    },
    {
        "key": "lus",
        "language": "Mizo"
    },
    {
        "key": "mn",
        "language": "Mongolian"
    },
    {
        "key": "my",
        "language": "Myanmar (Burmese)"
    },
    {
        "key": "ne",
        "language": "Nepali"
    },
    {
        "key": "no",
        "language": "Norwegian"
    },
    {
        "key": "ny",
        "language": "Nyanja (Chichewa)"
    },
    {
        "key": "or",
        "language": "Odia (Oriya)"
    },
    {
        "key": "om",
        "language": "Oromo"
    },
    {
        "key": "ps",
        "language": "Pashto"
    },
    {
        "key": "fa",
        "language": "Persian"
    },
    {
        "key": "pl",
        "language": "Polish"
    },
    {
        "key": "pt",
        "language": "Portuguese (Portugal, Brazil)"
    },
    {
        "key": "pa",
        "language": "Punjabi"
    },
    {
        "key": "qu",
        "language": "Quechua"
    },
    {
        "key": "ro",
        "language": "Romanian"
    },
    {
        "key": "ru",
        "language": "Russian"
    },
    {
        "key": "sm",
        "language": "Samoan"
    },
    {
        "key": "sa",
        "language": "Sanskrit"
    },
    {
        "key": "gd",
        "language": "Scots Gaelic"
    },
    {
        "key": "nso",
        "language": "Sepedi"
    },
    {
        "key": "sr",
        "language": "Serbian"
    },
    {
        "key": "sn",
        "language": "Shona"
    },
    {
        "key": "sd",
        "language": "Sindhi"
    },
    {
        "key": "si",
        "language": "Sinhala (Sinhalese)"
    },
    {
        "key": "sk",
        "language": "Slovak"
    },
    {
        "key": "sl",
        "language": "Slovenian"
    },
    {
        "key": "so",
        "language": "Somali"
    },
    {
        "key": "es",
        "language": "Spanish"
    },
    {
        "key": "su",
        "language": "Sundanese"
    },
    {
        "key": "sw",
        "language": "Swahili"
    },
    {
        "key": "sv",
        "language": "Swedish"
    },
    {
        "key": "tl",
        "language": "Tagalog (Filipino)"
    },
    {
        "key": "tg",
        "language": "Tajik"
    },
    {
        "key": "ta",
        "language": "Tamil"
    },
    {
        "key": "tt",
        "language": "Tatar"
    },
    {
        "key": "te",
        "language": "Telugu"
    },
    {
        "key": "th",
        "language": "Thai"
    },
    {
        "key": "ti",
        "language": "Tigrinya"
    },
    {
        "key": "ts",
        "language": "Tsonga"
    },
    {
        "key": "tr",
        "language": "Turkish"
    },
    {
        "key": "tk",
        "language": "Turkmen"
    },
    {
        "key": "ak",
        "language": "Twi (Akan)"
    },
    {
        "key": "uk",
        "language": "Ukrainian"
    },
    {
        "key": "ur",
        "language": "Urdu"
    },
    {
        "key": "ug",
        "language": "Uyghur"
    },
    {
        "key": "uz",
        "language": "Uzbek"
    },
    {
        "key": "vi",
        "language": "Vietnamese"
    },
    {
        "key": "cy",
        "language": "Welsh"
    },
    {
        "key": "xh",
        "language": "Xhosa"
    },
    {
        "key": "yi",
        "language": "Yiddish"
    },
    {
        "key": "yo",
        "language": "Yoruba"
    },
    {
        "key": "zu",
        "language": "Zulu"
    }
]

export default languages;