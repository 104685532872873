import { useEffect } from "react";
import "./FooterAd.css";


const FooterAd = (props) => { 
     // let adsbygoogle    
     useEffect(()=>{
      window.addEventListener('load', function (){
       window.addEventListener('load', function (){
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        })
        })
        
    }, [])
  return (
    <div className="sticky-ads" id="sticky-ads">
  <div
    className="sticky-ads-close"
    onClick={() => {
      document.getElementById("sticky-ads").style.display="none"
    }}
  >
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"
      />
    </svg>
  </div>
  <div className="sticky-ads-content">
    <ins
      className="adsbygoogle"
      style={{
        display: "inline-block",
        height: "70px",
        width: "100%",
        lineHeight: "70px"
      }}
      data-ad-client="ca-pub-7894154869919315"
      data-ad-slot="7057894606"
    ></ins>
  </div>
</div>
       
  );
};
export default FooterAd;
