
import React, { useState } from 'react'
import useCollapse from 'react-collapsed'
import "./CollapseMenu.css"

function CollapseMenu(props) {
  const [isExpanded, setExpanded] = useState(false)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })


  return (
    <div className='faqContainer'>

      <details>
        <summary>
          {props.faqText}
          <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </summary>
        <div className="details-info">
          <p>{props.faqAns}</p>
        </div>
      </details>



    </div>
  )
}

export default CollapseMenu