import axios from "axios"
import { useEffect, useState } from "react"
import { FileDrop } from 'react-file-drop'
import imageToBase64 from 'image-to-base64/browser';

import "./OcrUploadSection.css"
import cloudImg from "../images/cloudIcon.svg"
import blueCloudIcon from "../images/blueCloud.svg"
import searchIcon from "../images/searchIcon.svg"
import toast from "react-hot-toast";
import mixpanel from "mixpanel-browser";
import MiddlePageAd from "./adsSection/middlePageAd";

const OcrUploadSection = (props) => {
    const [dragging, setDragging] = useState(false)

    function input() {
        document.getElementById("input_file").click()


        mixpanel.track('Clicked on Upload File ', {});


    }
    const uploadImgFunction = (e) => {
        let fileInfo = e.target.files[0]
        if (fileInfo.size < 1024000) {
            if (fileInfo.type == "image/jpeg" || fileInfo.type == "image/png") {
                props.uploadStatus(true)
                props.imgName(e.target.files[0])
                const file = e.target.files[0]
                let reader = new FileReader();
                reader.readAsDataURL(file)
                reader.onload = () => {
                    onLoad(reader.result)
                }
            } else {
                toast.error("Wrong format")
            }


        } else {
            toast.error("Please Upload another file")
        }


    }

    const EnterUploadImgFunction = (e) => {
        if (e.key === "Enter") {
            inputImgUrlFunction()

        }
    }
    const dragImgFunction = (e) => {
        const draggerOutput = () => {
            props.imgName(e[0])
            let draggedFile = e[0]
            let reader = new FileReader();
            reader.readAsDataURL(draggedFile)
            reader.onload = () => {
                onLoad(reader.result)

            }
        }
        if (e[0].type === "image/png") {
            draggerOutput()

        } else if (e[0].type === "image/jpeg") {
            draggerOutput()

        } else {
            toast.error("Wrong Format")
        }

        mixpanel.track('Dragged an Image ', {});


    }
    const dragOver = () => {
        setDragging(true)
        // console.log("dragging", dragging)
    }
    const dragOut = () => {
        setDragging(false)
    }

    const inputImgUrlFunction = () => {
        props.uploadConvertStatus(true)
        let urlInputBar = document.getElementById("urlSearch")
        let string = urlInputBar.value
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);


        if (res != null || string.includes("data:image")) {

            imageToBase64(urlInputBar.value) // Image URL
                .then(
                    (response) => {
                        onLoad("data:image/png;base64," + response)
                    }
                )
                .catch(
                    (error) => {
                        toast.error("Please try different link")

                    }
                )

        } else {
            toast.error("Please try valid link")
        }

        mixpanel.track('Clicked on Search button', {});

    }



    useEffect(() => {


    }, [props.bodyContent]);

    const onLoad = (fileString) => {
        let tempContent = fileString.indexOf("base64")
        props.setImg(fileString)
        props.onChange(fileString)
        let data = fileString.slice(tempContent + 7)
        axios.request({
            method: "post",
            url: "https://backend.ocreditor.com/api/image/text",
            // url: "http://localhost:8081/api/image/text",
            data: {
                img: data
            },
            onUploadProgress: (p) => {
                props.setProgressStatus(p.loaded / p.total)
            }
        }).then(response => {
            if (response.data.data && response.data.data.length > 0) {
                if (response.data.data) {

                    props.processingText(true)
                    props.setBodyContent(response.data.data)
                    props.setProgressStatus(70)
                    props.setProgressStatus(80)
                    props.setProgressStatus(100)


                }
            } else {
                props.processingText(true)
                props.setProgressStatus(70)
                props.setProgressStatus(80)
                props.setProgressStatus(100)
                toast.error("No Text Found")

            }
        })

    }

    return (
        <div className="uploadSection">

            <FileDrop onDrop={(event) => {
                dragImgFunction(event);
            }} onDragOver={dragOver} onFrameDragLeave={dragOut} >
                <img src={dragging ? blueCloudIcon : cloudImg} id="cloudImg" />

                <p id='ocrDragText'
                    style={{ color: dragging ? "#279CF0" : "#4E4E4E" }}
                >Drag an image here</p>
                <span id='ocrUploadText' onClick={input}
                    style={{ opacity: dragging ? "0.4" : "1" }}

                >Upload a file</span>

                <input type="file" id='input_file' accept="image/png,image/jpeg" hidden onChange={(event) => {
                    uploadImgFunction(event);
                }}

                />
                <p id='orr'
                    style={{ opacity: dragging ? "0.3" : "1" }}

                >or</p>
                <div className="urlSearchWrapper"
                    style={{ opacity: dragging ? "0.3" : "1" }}
                >
                    <input id='urlSearch' type="url" placeholder='Paste an url' style={{ paddingRight: "73px" }} onKeyDown={(e) => EnterUploadImgFunction(e)} />
                    <img id='searchIcon' src={searchIcon} onClick={inputImgUrlFunction} />
                </div>

                <div className="adContainer">
                    <div className="adSection">
                        {/* <img src="https://tpc.googlesyndication.com/simgad/1675980580533340206"></img> */}
                        {/* <MiddlePageAd /> */}
                    </div>
                    <div className="convertBtn"><button>Get Text</button></div>
                </div>
                {/* <div>
                    
                </div> */}

                <p id='ocrNoteText'>Supported file types: JPG, PNG | Files are deleted after converting</p>

            </FileDrop>
        </div>
    )
}
export default OcrUploadSection;