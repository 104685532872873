import "./Use.css"


const Use = (props) => {
    return (
        <div className="UseWhyContainer">
            <div className="cardWhyImage">
                <img src={props.image} />
            </div>

            <h4 className="UseWhyHeader">{props.title}</h4>
            <p className="UseWhyDesc">{props.text}</p>
            {/* <div className="cardImage">
                <img src={props.image} />
            </div>
            {props.title && <h3>{props.title}</h3>}
            <p>{props.text}</p> */}
        </div>
    )
}

export default Use;